import React from "react"
import * as Icon from "react-feather"

const navConfigAdmin = [
    {
        type: "groupHeader",
        groupTitle: "ADMINISTRADOR"
    },
    {
        id: "person_manager",
        title: "Gerenciar Pessoas",
        type: "item",
        icon: <Icon.User size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/gerenciar-pessoas",
    },
    {
        id: "class_manager",
        title: "Gerenciar Turmas",
        type: "item",
        icon: <Icon.Award size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/gerenciar-turmas"
    },
]

export default navConfigAdmin
