import React from "react"
import * as Icon from "react-feather"

const navConfigCoord = [
    {
        type: "groupHeader",
        groupTitle: "COORDENADOR"
    },
    {
        id: "coord-calendar",
        title: "Agenda",
        type: "collapse",
        icon: <Icon.Calendar size={20} />,
        children: [
            {
                id: "coord-alerts",
                title: "Avisos",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/coord/agenda"
            },
        ]
    },
    {
        id: "coord-students",
        title: "Alunos",
        type: "item",
        icon: <Icon.User size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/coord/alunos",
        // filterBase: "/todo/all"
    },
    {
        id: "coord-teachers",
        title: "Professores",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/coord/professores",
        // filterBase: "/todo/all"
    },
    {
        id: "coord-grades",
        title: "Notas",
        type: "collapse",
        badge: "success",
        // badgeText: "3",
        icon: <Icon.Clipboard size={20} />,
        children: [
            {
                id: "coord-bimestral-grades",
                title: "Notas Bimestrais",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/coord/notas"
            },
            {
                id: "coord-final-grades",
                title: "Notas Finais",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/coord/nota-final"
            }
        ]
    },
    {
        id: "coord-performance",
        title: "Desempenho",
        type: "collapse",
        badge: "success",
        // badgeText: "3",
        icon: <Icon.PieChart size={20} />,
        children: [
            {
                id: "coord-bai",
                title: "BAI",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/prof/bai"
            },
            {
                id: "coord-grade-card",
                title: "Boletim",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/coord/boletim"
            },
            {
                id: "coord-class-council",
                title: "Conselho de Classe",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/conselho"
            }
        ]
    },
    {
        id: "instei-legacy",
        title: "Instei Legado",
        type: "item",
        icon: <Icon.ExternalLink size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/legado"
    }
]

export default navConfigCoord

