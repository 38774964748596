import React from "react"
import * as Icon from "react-feather"

const navConfigTeacher = [
    {
        type: "groupHeader",
        groupTitle: "PROFESSOR"
    },
    {
        id: "teacher-schedule",
        title: "Agenda",
        type: "collapse",
        icon: <Icon.Calendar size={20} />,
        children: [
            {
                id: "teacher-alerts",
                title: "Avisos",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/prof/agenda"
            },
        ]
    },
    {
        id: "teacher-diary",
        title: "Diário",
        type: "item",
        icon: <Icon.UserCheck size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/prof/diario",
    },
    {
        id: "teacher-grades",
        title: "Notas",
        type: "item",
        icon: <Icon.Clipboard size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/prof/notas",
        // filterBase: "/todo/TodoGrades"
    },
    {
        id: "teacher-performance",
        title: "Desempenho",
        type: "collapse",
        badge: "success",
        // badgeText: "3",
        icon: <Icon.PieChart size={20} />,
        children: [
            {
                id: "teacher-bai",
                title: "BAI",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/prof/bai"
            },
            {
                id: "teacher-observation",
                title: "Conselho de Classe",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/prof/obs-boletim"
            },
            {
                id: "teacher-final-recuperation",
                title: "Recuperação Final",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/recuperacao"
            }
        ]
    },
]
export default navConfigTeacher
