export const GRADE_TYPE = {
    PROVA: 1,
    TRABALHO: 2,
    ATIVIDADE: 3,
    INTERDISCIPLINAR: 4,
    PROJETO: 5,
    SIMULADO: 6,
    PARTICIPACAO: 7,
    FORMATIVA: 8,
    VERIFICACAO: 9,
    EXTRA: 10,
    RECUPERACAO: 11
}

export const BIMESTER = {
    PRIMEIRO_BIMESTRE: 1,
    SEGUNDO_BIMESTRE: 2,
    TERCEIRO_BIMESTRE: 3,
    QUARTO_BIMESTRE: 4
}

export const SUBJECT = {
    PORTUGUES: 1,
    MATEMATICA: 2,
    HISTORIA: 3,
    GEOGRAFIA: 4,
    CIENCIAS: 5,
    INGLES: 6,
    ESPANHOL: 7,
    QUIMICA: 8,
    FISICA: 9,
    BIOLOGIA: 10,
    FILOSOFIA: 11,
    SOCIOLOGIA: 12,
    ARTE: 13,
    EDUCACAOFISICA: 14,
    DESENHOGEOMETRICO: 15,
    ARTESPLASTICAS: 16,
    ARTESCENICAS: 17,
    MUSICA: 18,
    ALGEBRA: 19,
    GRAMATICA: 20,
    REDACAO: 21,
    LITERATURA: 22,
    LABORATORIO: 23,
    EXPERIENCIA: 24,
    PROJETOS: 25,
    EUOUTRONOS: 26,
    CORPOGESTOSMOVIMENTOS: 27,
    TRACOSCORESONSFORMAS: 28,
    ESCUTAFALAPENSAMENTOS: 29,
    ESPACOTEMPOQUANTIDADE: 30,
    DANCAJUDO: 31,
    PROJETOBILINGUE: 32,
    PROJETOMUSICA: 33,
    PROJETOINFORMATICA:34,
    APRENDIZAGEMSOCIAL: 35
}

export const SCHOOLGRADE = {
    MATERNAL_I: 1,
    MATERNAL_II: 2,
    JARDIM_I: 3,
    JARDIM_II: 4,
    PRIMEIRO_ANO: 5,
    SEGUNDO_ANO: 6,
    TERCEIRO_ANO: 7,
    QUARTO_ANO: 8,
    QUINTO_ANO: 9,
    SEXTO_ANO: 10,
    SETIMO_ANO: 11,
    OITAVO_ANO: 12,
    NONO_ANO: 13,
    PRIMEIRA_SERIE: 14,
    SEGUNDA_SERIE: 15,
    TERCEIRA_SERIE: 16
}

export const SUBJECTGROUP = {
    EXPERIENCIA : 1,
    PROJETOS : 2,
}

export const RECIPIENT_TYPE = {
    TURMA : 1,
    ALUNO : 2,
    TODOSALUNOS: 3,
    RESPONSAVEL: 4,
    TODOSRESPONSAVEIS : 5,
    PROFESSOR : 6,
    TODOSPROFESSORES : 7,
    COLABORADORES : 8,
    TODOS : 9,
    COORDENADOR: 10
}

export const PERSON_TYPE = {
    ALUNO: 1,
    RESPONSAVEL: 2,
    PROFESSOR: 3,
    COORDENADOR: 4,
    DIRETOR: 5,
    ADMINISTRADOR: 6
}

export const DIARY_TYPE = {
    MESSAGE : 1,
    REPLY : 2
}  

