import React from "react"
import * as Icon from "react-feather"

const navConfigStudent = [
    {
        type: "groupHeader",
        groupTitle: "ALUNO"
    },
    {
        id: "student-calendar",
        title: "Agenda",
        type: "collapse",
        icon: <Icon.Calendar size={20} />,
        children: [
            {
                id: "student-alerts",
                title: "Avisos",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/aluno/agenda/avisos"
            },
            {
                id: "student-dailys",
                title: "Diário",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/aluno/agenda/diario"
            },
        ]
    },
    {
        id: "student-performance",
        title: "Desempenho",
        type: "collapse",
        badge: "success",
        // badgeText: "3",
        icon: <Icon.PieChart size={20} />,
        children: [
            {
                id: "student-absence",
                title: "Faltas",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/aluno/presenca"
            },
            {
                id: "student-grade-card",
                title: "Boletim",
                type: "item",
                icon: <Icon.Circle size={12} />,
                permissions: ["admin", "editor"],
                navLink: "/aluno/boletim"
            },
        ]
    }
]
export default navConfigStudent

